<script setup>
import { onBeforeMount, computed, ref, watch } from "vue";
import { useRouter } from "vue-router/composables";
import { storeToRefs } from "pinia";
import { useNavbarStore } from "@/stores/navbarStore";
import { useSearchStore } from "@/stores/searchStore";
import { useNotificationsStore } from "@/stores/notificationsStore";
import { useCoreStore } from "@/stores/coreStore";
import MainNavBarLinkOption from "@/components/main-navbar/MainNavBarLinkOption";
import MainNavbarUserName from "@/components/main-navbar/MainNavbarUserName";
import MainNavbarIconOption from "@/components/main-navbar/MainNavbarIconOption";
import MainNavbarNotifications from "@/components/main-navbar/MainNavbarNotifications.vue";
import MainNavbarUserModal from "@/components/main-navbar/MainNavbarUserModal.vue";
import MainNavbarGeneralModal from "@/components/main-navbar/MainNavbarGeneralModal.vue";
import SearchBar from "@/components/general/SearchBar";

const isLoading = ref(false);
const router = useRouter();
const navbarStore = useNavbarStore();
const searchStore = useSearchStore();
const navbarColor = ref(null);
const { notificationsUnread } = storeToRefs(useNotificationsStore());
const { configurations } = storeToRefs(useCoreStore());

const filteredNavbarItem = computed(() => {
  return navbarStore.navbarItems.find((item) => {
    return item.name === navbarStore.activeCategory;
  });
});

const openUserModal = () => {
  navbarStore.closeAllModals();
  searchStore.hideSearch();
  navbarStore.hideSupportButton();
  navbarStore.changeUserModalOpen(true);
};

const openGeneralNavbarModal = (category) => {
  navbarStore.changeActiveCategory(category);
  navbarStore.closeAllModals();
  searchStore.hideSearch();
  navbarStore.hideSupportButton();
  pushDataLayer(category);
  navbarStore.changeGeneralNavbarModalOpen(true);
};

const openNotificationModal = () => {
  navbarStore.closeAllModals();
  searchStore.hideSearch();
  navbarStore.hideSupportButton();
  navbarStore.changeNotificationModalOpen(true);
};

const showSearchBox = () => {
  navbarStore.closeAllModals();
  searchStore.resetSearchResult();
  searchStore.toggleStateSearch();
  pushDataLayer("Búsqueda");
  searchStore.showSearch === true
    ? navbarStore.hideSupportButton()
    : navbarStore.showSupportButton();
};

const goToEcommerce = () => {
  pushDataLayer("Tienda");
  router.push("/e-commerce").catch(() => {});
};

const goToTour = () => {
  pushDataLayer("Tour de Gente");
  router.push("/peopleTour").catch(() => {});
  navbarStore.closeAllModals();
};

// const goToTalentFest = () => {
//   pushDataLayer("Talent Fest");
//   router.push("/talentFest").catch(() => {});
//   navbarStore.closeAllModals();
// };

const goToVamosPaLaCalle = () => {
  pushDataLayer("Vamos pa' la calle");
  router.push("/vamosPaLaCalle").catch(() => {});
  navbarStore.closeAllModals();
};

const pushDataLayer = (brandname) => {
  window.dataLayer.push({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Interacciones Encabezado",
    event_label: brandname,
    interaction: "True",
    component_name: "Click",
  });
};

const updateNavbarColor = () => {
  navbarColor.value = configurations.value.find(
    (item) => item.key === "NAVBAR_COLOR"
  ).value;
};

const colorToPass = computed(() => {
  return navbarColor?.value ? navbarColor.value : "#F72B30";
});

onBeforeMount(async () => {
  isLoading.value = true;
  updateNavbarColor();
  await navbarStore.actGetNavbarItems();
  isLoading.value = false;
});

watch(
  () => router.currentRoute,
  () => {
    updateNavbarColor();
  }
);
</script>
<template>
  <div
    class="tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-h-16 lg:tw-h-[7rem] tw-z-50"
  >
    <div
      :class="{ 'tw-bg-red-400': !navbarColor }"
      class="tw-py-2 md:tw-py-1 tw-px-3 xl:tw-pr-8 lg:tw-h-[70px]"
      :style="{ backgroundColor: navbarColor }"
    >
      <!-- Menu mobile -->
      <div class="tw-flex tw-justify-between md:tw-hidden">
        <i
          class="tw-text-white tw-text-xl"
          :class="
            !navbarStore.getAllModalsStatus()
              ? 'icon-hamburguer'
              : 'icon-cerrar-simple'
          "
          @click="openUserModal"
        ></i>
        <div class="tw-flex tw-space-x-6">
          <i
            class="icon-search-bold tw-text-white tw-text-xl"
            @click="showSearchBox()"
          ></i>
          <i
            class="icon-bell-1 tw-text-white tw-text-xl"
            @click="openNotificationModal"
          ></i>
        </div>
      </div>
      <!-- end - Menu mobile -->
      <!-- Menu Desktop -->
      <div
        class="tw-hidden md:tw-grid tw-grid-cols-col3_fit_first_and_last tw-gap-4 tw-h-full"
      >
        <div class="tw-hidden lg:tw-block">
          <router-link
            :to="{ name: 'Home' }"
            @click.native="pushDataLayer('Logo')"
            class="tw-flex tw-h-full"
          >
            <div class="tw-flex tw-items-center tw-space-x-4">
              <img
                src="@/assets/img/icons/logo-embajadores.png"
                class="tw-w-14 tw-h-14"
                alt="embajadores"
              />
            </div>
          </router-link>
        </div>
        <div class="tw-place-self-center">
          <div
            class="tw-flex md:tw-space-x-8 lg:tw-space-x-10 xl:tw-space-x-14 tw-items-center tw-h-full"
          >
            <div v-for="(item, idx) in navbarStore.navbarItems" :key="idx">
              <MainNavBarLinkOption
                :text="item.name"
                @click.native="openGeneralNavbarModal(item.name)"
                :active="
                  navbarStore.isGeneralNavbarModalOpen &&
                  navbarStore.activeCategory === item.name
                "
                :border-color-prop="colorToPass"
              />
            </div>
            <MainNavBarLinkOption
              text="Tour de Gente"
              @click.native="goToTour()"
              :active="router.currentRoute.path === '/peopleTour'"
              :border-color-prop="colorToPass"
            />
<!--            <MainNavBarLinkOption-->
<!--              text="Talent Fest"-->
<!--              @click.native="goToTalentFest()"-->
<!--              :active="router.currentRoute.path === '/talentFest'"-->
<!--              :border-color-prop="colorToPass"-->
<!--            />-->
            <MainNavBarLinkOption
              text="Vamos Pa' La Calle"
              @click.native="goToVamosPaLaCalle()"
              :active="router.currentRoute.path === '/vamosPaLaCalle'"
              :border-color-prop="colorToPass"
            />
            <MainNavBarLinkOption
              text="Tienda"
              @click.native="goToEcommerce()"
              :active="router.currentRoute.path === '/e-commerce'"
              :border-color-prop="colorToPass"
            />
          </div>
        </div>
        <div class="">
          <div
            class="tw-flex tw-space-x-6 tw-items-center tw-justify-end tw-h-full"
          >
            <MainNavbarIconOption
              icon="icon-calendario-activo"
              @click.native="$router.push('/calendar')"
              :border-color-prop="colorToPass"
            />
            <MainNavbarIconOption
              icon="icon-search-bold"
              @click.native="showSearchBox()"
              :border-color-prop="colorToPass"
            />
            <MainNavbarIconOption
              icon="icon-bell-1"
              :isOpen="navbarStore.isNotificationModalOpen"
              :notificationNumber="notificationsUnread.length"
              @click.native="openNotificationModal"
              :border-color-prop="colorToPass"
            />
            <MainNavbarIconOption
              icon="icon-user-circle"
              :isOpen="navbarStore.isUserModalOpen"
              @click.native="openUserModal"
              :border-color-prop="colorToPass"
            />
          </div>
        </div>
      </div>
      <!-- end - Menu Desktop -->
    </div>
    <!-- Barra nombre -->
    <MainNavbarUserName class="lg:tw-h-[30px]" />
    <!-- end - Barra nombre -->
    <SearchBar v-if="searchStore.showSearch" />
    <MainNavbarUserModal v-if="navbarStore.isUserModalOpen" />
    <MainNavbarGeneralModal
      v-if="navbarStore.isGeneralNavbarModalOpen"
      :main-item="filteredNavbarItem"
    />
    <MainNavbarNotifications v-if="navbarStore.isNotificationModalOpen" />
  </div>
</template>
