<script setup>
import { ref } from "vue";
import AtomText from "@/components/atoms/AtomText.vue";
import MoleculeCardImageTextButton from "@/components/molecules/MoleculeCardImageTextButton.vue";

const cardsContent = ref([
  {
    id: 1,
    imageSrc: "assets/img/vamospalacalle/plantas_o_malterias.png",
    title: "Cervecerías o Malterías",
    description:
      "Conoce los lugares donde se hacen las polas. Nuestras Cervecerías de Tocancipá, Del Valle, Unión, Barranquilla, Tibasosa y Bucaramanga. Fábrica de Tapas, Indugral y Maltería Tropical y de Tibitó.",
    button: true,
    buttonText: "Inscribirse al evento",
    link: "https://forms.office.com/r/HX5E8YfBrJ",
  },
  {
    id: 2,
    imageSrc: "assets/img/vamospalacalle/voluntariados.png",
    title: "Voluntariados #MeUno",
    description:
      "Lideramos una industrial natural, incluyente y local con programas de sostenibilidad que transforman. Desde #MeUno, nuestro programa de voluntariado, estaremos más cerca de nuestra cadena de valor, con jornadas que dan más sentido a lo que hacemos día a día.",
    button: false,
    buttonText: "",
  },
  {
    id: 3,
    imageSrc: "assets/img/vamospalacalle/salida_masiva.png",
    title: "Salida al mercado",
    description:
      "En la calle están nuestros clientes; tenderos y tenderas que nos han visto crecer, ¡y que todavía nos fían! Vamos a conocer sus historias, a mejorar sus negocios y a aprender del emocionante mercado colombiano.",
    button: false,
    buttonText: "",
  },
  {
    id: 4,
    imageSrc: "assets/img/vamospalacalle/insights.png",
    title: "Insights",
    description:
      "Qué privilegio escuchar lo que los consumidores piensan de nuestros productos, experiencias y del impacto que generamos en Colombia. Vamos a escucharlos y a capturar los mejores aprendizajes.",
    button: false,
    buttonText: "",
  },
  {
    id: 5,
    imageSrc: "assets/img/vamospalacalle/centros_de_distribucion.png",
    title: "Centros de Distribución",
    description:
      "Nuestras bebidas se distribuyen en todo el territorio colombiano desde 55 Centros de Distribución. Cuando los conozcas, podrás aprender sobre almacenamiento, disponibilidad, alcance y ¡hasta subirte a un camión de reparto!",
    button: false,
    buttonText: "",
  },
]);
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-gap-5">
    <atom-text
      tag="h2"
      font="robotoBold"
      text-size-mobile="xl"
      text-size-desktop="2xl"
      text-color="black"
    >
      Eventos a los que puedes asistir
    </atom-text>
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-4 lg:tw-gap-5">
      <molecule-card-image-text-button
        v-for="(card, idx) in cardsContent"
        class="tw-w-9/12 lg:tw-w-auto tw-place-self-center"
        :key="idx"
        :src="card.imageSrc"
        :title="card.title"
        :description="card.description"
        :button="card.button"
        :button-text="card.buttonText"
        :link="card.link"
      />
    </div>
  </div>
</template>

<style scoped></style>
