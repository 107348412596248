<script setup>
import config from "@/services/config";
import { useNavbarStore } from "@/stores/navbarStore";
import { useSearchStore } from "@/stores/searchStore";
// import { useStore } from "@/store";
import { pushDataLayer } from "@/helpers/datalayersHelpers";
import { useRouter } from "vue-router/composables";

const searchStore = useSearchStore();
const navbarStore = useNavbarStore();
// const store = useStore();
const router = useRouter();

const uriResources = config.beerOfficeURL;

const openGeneralNavbarModal = (category) => {
  navbarStore.changeActiveCategory(category);
  navbarStore.closeAllModals();
  searchStore.hideSearch();
  navbarStore.hideSupportButton();
  dataLayer(category);
  navbarStore.changeGeneralNavbarModalOpen(true);
};

const goToHome = () => {
  dataLayer("Home");
  router.push("/");
  navbarStore.closeAllModals();
};

const goToEcommerce = () => {
  dataLayer("Tienda");
  router.push("/e-commerce");
  navbarStore.closeAllModals();
};

const goToTour = () => {
  dataLayer("Tour de Gente");
  router.push("/peopleTour");
  navbarStore.closeAllModals();
};

// const goToTalentFest = () => {
//   dataLayer("Talent Fest");
//   router.push("/talentFest");
//   navbarStore.closeAllModals();
// };

const goToVamosPaLaCalle = () => {
  dataLayer("Vamos pa' la calle");
  router.push("/vamosPaLaCalle");
  navbarStore.closeAllModals();
};

/*const toggleStateCalendar = () => {
  dataLayer("Calendario");
  if (store.state.modCalendar.viewCalendar) {
    store.commit("modCalendar/hideCalendar");
  } else {
    store.commit("modCalendar/showCalendar");
    navbarStore.closeAllModals();
  }
};*/

const dataLayer = (brandname) => {
  pushDataLayer({
    event: "GAEvent",
    event_category: "Home",
    event_action: "Interacciones Encabezado",
    event_label: brandname,
    interaction: "True",
    component_name: "Click",
  });
};
</script>
<template>
  <div
    class="tw-w-80 md:tw-hidden tw-bg-white tw-pb-3 tw-pt-1 tw-px-4 tw-rounded-bl-2xl tw-rounded-br-2xl"
  >
    <div
      @click="goToHome"
      class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-border-b tw-border-gray-300 tw-py-3"
    >
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <i class="icon-Plataformas tw-text-xl"></i>
      </div>
      <div class="tw-col-span-6">
        <p class="tw-mb-0 tw-text-base tw-font-roboto">Inicio</p>
      </div>
      <div class="tw-col-span-3"></div>
      <div class="tw-col-span-1 tw-flex tw-items-center">
        <i class="icon-flecha-der tw-text-xl tw-text-red-400"></i>
      </div>
    </div>
    <div v-for="(item, idx) in navbarStore.navbarItems" :key="idx">
      <div
        @click="openGeneralNavbarModal(item.name)"
        class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-border-b tw-border-gray-300 tw-py-3"
      >
        <div class="tw-col-span-2 tw-flex tw-items-center">
          <img
            :src="uriResources + '/' + item.icon"
            class="tw-max-h-5"
            alt=""
          />
        </div>
        <div class="tw-col-span-6">
          <p class="tw-mb-0 tw-text-base tw-font-roboto">{{ item.name }}</p>
        </div>
        <div class="tw-col-span-3"></div>
        <div class="tw-col-span-1 tw-flex tw-items-center">
          <i class="icon-flecha-der tw-text-xl tw-text-red-400"></i>
        </div>
      </div>
    </div>
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-text-black tw-border-b tw-border-gray-300 tw-py-3"
    >
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <i class="icon-Calendar-menu tw-text-xl"></i>
      </div>
      <div class="tw-col-span-6" @click="goToTour()">
        <p class="tw-mb-0 tw-text-base tw-font-roboto">Tour de Gente</p>
      </div>
      <!--
      <div class="tw-col-span-6" @click="toggleStateCalendar()">
        <p class="tw-mb-0 tw-text-base tw-font-roboto">Calendario</p>
      </div>
-->
      <div class="tw-col-span-3"></div>
      <div class="tw-col-span-1 tw-flex tw-items-center">
        <i class="icon-flecha-der tw-text-xl tw-text-red-400"></i>
      </div>
    </div>
    <!--
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-text-black tw-border-b tw-border-gray-300 tw-py-3"
    >
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <i class="icon-talentfest tw-text-xl"></i>
      </div>
      <div class="tw-col-span-6" @click="goToTalentFest()">
        <p class="tw-mb-0 tw-text-base tw-font-roboto">Talent Fest</p>
      </div>
      <div class="tw-col-span-3"></div>
      <div class="tw-col-span-1 tw-flex tw-items-center">
        <i class="icon-flecha-der tw-text-xl tw-text-red-400"></i>
      </div>
    </div>
-->
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-text-black tw-border-b tw-border-gray-300 tw-py-3"
    >
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <i class="icon-vamospalacalle tw-text-xl"></i>
      </div>
      <div class="tw-col-span-6" @click="goToVamosPaLaCalle()">
        <p class="tw-mb-0 tw-text-base tw-font-roboto">Vamos pa' la calle</p>
      </div>
      <div class="tw-col-span-3"></div>
      <div class="tw-col-span-1 tw-flex tw-items-center">
        <i class="icon-flecha-der tw-text-xl tw-text-red-400"></i>
      </div>
    </div>
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-3 tw-items-center tw-text-black tw-border-b tw-border-gray-300 tw-py-3"
    >
      <div class="tw-col-span-2 tw-flex tw-items-center">
        <i class="icon-Tienda tw-text-xl"></i>
      </div>
      <div class="tw-col-span-6" @click="goToEcommerce">
        <p class="tw-mb-0 tw-text-base tw-font-roboto">Tienda</p>
      </div>
      <div class="tw-col-span-3"></div>
      <div class="tw-col-span-1 tw-flex tw-items-center">
        <i class="icon-flecha-der tw-text-xl tw-text-red-400"></i>
      </div>
    </div>
  </div>
</template>
